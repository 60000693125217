function domReady (fn) {
  // If we're early to the party
  document.addEventListener('DOMContentLoaded', fn)
  // If late; I mean on time.
  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    fn()
  }
}

function getOffset (el) {
  const rect = el.getBoundingClientRect()
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY
  }
}

function burger () {
  const burger = document.querySelector('.burger')
  burger.classList.add('unToggled')

  burger.addEventListener('click', function () {
    burger.classList.toggle('toggled')
    burger.classList.toggle('unToggled')
  })
}

function spNav () {
  const sections = document.querySelectorAll('.sub-sections .sub-section')
  const CLASSNAME = 'opened'
  let activeSection = ''

  if (!sections) return

  for (const section of sections) {
    section.addEventListener('click', function () {
      // console.log(section.id, activeSection)

      if (section.id === activeSection) {
        section.classList.remove(CLASSNAME)
        activeSection = ''
        return
      }

      activeSection = section.id

      for (const s of sections) {
        s.classList.remove(CLASSNAME)
      }
      section.classList.toggle(CLASSNAME)
    })
  }
}

function animate () {
  const OFFSET = 0
  const ANIMATIONS_OUT = {
    rollin: 'rollout',
    slidein: 'slideout',
    fadein: 'fadeout'
  }
  const elements = document.querySelectorAll('[data-animation]')
  const animations = []
  const animated = []

  for (const element of elements) {
    const animationName = element.dataset.animation
    const removeName = ANIMATIONS_OUT[animationName]

    if (!removeName) {
      console.warn('animation error (no animation out)', element)
      continue
    }

    const animationOffset = Number(element.dataset.animationOffset) || 0
    const offsetTop = element.getBoundingClientRect()?.top

    animations.push({
      element,
      animationName,
      removeName,
      offsetTop,
      animationStart: offsetTop && Math.ceil(offsetTop) - OFFSET + animationOffset
    })
  }

  function updateAnimations () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY

    for (let index = 0; index < animations.length; index++) {
      const animation = animations[index]
      const { element, animationName, removeName, animationStart } = animation
      if (scrolled >= animationStart - screenHeight && !animated.includes(index)) {
        element.classList.add(animationName)
        element.classList.remove(removeName)
        animated.push(index)
      }
    }
  }

  updateAnimations()
  window.addEventListener('scroll', updateAnimations)
}

function scrollTop () {
  const element = document.getElementById('scroll-top')

  function handler () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY

    if (scrolled > (screenHeight / 2)) {
      element.classList.remove('hidden')
    } else {
      element.classList.add('hidden')
    }
  }

  window.addEventListener('scroll', handler)
}

function landing () {
  const landing = document.getElementById('landing-bg')
  const header = document.querySelector('header')

  if (!landing || !header) return

  function handler () {
    const screenHeight = window.innerHeight
    const scrolled = window.scrollY
    const CLASSNAME = 'scrolled'

    if (scrolled > screenHeight) {
      landing.classList.add(CLASSNAME)
      header.classList.add(CLASSNAME)
    } else {
      landing.classList.remove(CLASSNAME)
      header.classList.remove(CLASSNAME)
    }
  }

  window.addEventListener('scroll', handler)
  handler()
}

function gallery () {
  const modal = document.getElementById('gallery-modal')
  const mainImage = document.getElementById('zoomed-image')
  const closeButton = document.getElementById('close-modal')

  if (!modal || !mainImage || !closeButton) return

  const closeModal = function () {
    modal.classList.remove('opened')
    mainImage.removeAttribute('src')
  }

  closeButton.addEventListener('click', function () {
    closeModal()
  })

  modal.addEventListener('click', function (e) {
    if (e.target.classList.contains('outer') || e.target.classList.contains('inner')) {
      closeModal()
    }
  })

  const images = document.querySelectorAll('#gallery .gallery-grid img')
  if (!images) return

  for (const image of images) {
    image.addEventListener('click', function (e) {
      const sibling = e.target.parentNode.nextElementSibling
      const source = sibling.querySelector('source')
      const srcset = source.srcset

      mainImage.setAttribute('src', srcset)
      modal.classList.add('opened')
    })
  }
}

function parallax () {
  const effects = []
  const screenHeight = window.innerHeight
  const wrappers = document.querySelectorAll('.parallax-img')
  for (const wrapper of wrappers) {
    const { top } = getOffset(wrapper)
    const { height } = wrapper.getBoundingClientRect()
    const image = wrapper.querySelector('img')

    effects.push({
      image,
      topThreshold: top + height,
      bottomThreshold: top - screenHeight,
      center: top - (screenHeight / 2) + (height / 2)
    })
  }

  const handler = function () {
    const scrolled = window.scrollY
    for (const effect of effects) {
      const { image, topThreshold, bottomThreshold, center } = effect

      if (scrolled <= topThreshold && scrolled >= bottomThreshold) {
        image.style.transform = `translateY(${scrolled - center}px)`
      } else {
        image.style.transform = 'none'
      }
    }
  }

  window.addEventListener('scroll', handler)
  handler()
}

function contact () {
  const contactForm = document.getElementById('contact_form')
  const formError = document.getElementById('form_error')

  if (!contactForm) return

  function handleSubmit (e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)

    formError.innerHTML = ''

    if (data?.name && data?.email && data?.message) {
      // const url = 'https://kyoho-mail.free.beeceptor.com'
      const url = './mailform/sendmail.php'
      contactForm.classList.add('loading')

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })
        .then(function (response) {
          if (response.ok) {
            contactForm.innerHTML = 'メールを送信しました'
          } else {
            formError.innerHTML = '送信できませんでした'
          }
          contactForm.classList.remove('loading')
        })
        .catch(function (error) {
          console.log(error)
          formError.innerHTML = '送信できませんでした'
          contactForm.classList.remove('loading')
        })
    }
  }

  contactForm.addEventListener('submit', handleSubmit)
}

function maps () {
  if (document.querySelector('.map > iframe')) {
    const maps = document.querySelectorAll('.map')
    for (const map of maps) {
      const frame = map.querySelector('iframe')
      map.addEventListener('click', function () {
        frame.style.pointerEvents = 'auto'
      })
      map.addEventListener('mouseleave', function () {
        frame.style.pointerEvents = 'none'
      })
    }
  }
}

function scrollHint () {
  // eslint-disable-next-line no-new, no-undef
  new ScrollHint('.js-scrollable', {
    // scrollHintIconAppendClass: 'scroll-hint-icon-white', // white-icon will appear
    // applyToParents: true,
    i18n: {
      scrollable: 'スクロールできます'
    }
  })
}

function orderSteps () {
  const form = document.getElementById('order_form')
  const formError = document.getElementById('form_error')

  const carMaker = document.querySelector('[name="car_maker"]')
  const carModel = document.querySelector('[name="car_model"]')
  const carType = document.querySelector('[name="car_type"]')
  const wheel = document.querySelector('[name="wheel"]')
  const size = document.querySelector('[name="size"]')
  const message = document.querySelector('[name="message"]')
  const cap = document.querySelector('[name="cap"]')
  // const name = document.querySelector('[name="name"]')
  // const phone = document.querySelector('[name="phone"]')
  // const address = document.querySelector('[name="address"]')
  // const deliveryAddress = document.querySelector('[name="delivery_address"]')
  // const email = document.querySelector('[name="email"]')

  if (!form) return

  const stepTabs = document.querySelectorAll('.step-tab')
  const steps = document.querySelectorAll('.step')

  function handleTabChange (id) {
    if (!id) return

    // if (id === 'step1') {
    //   const validateName = name.reportValidity()
    //   const validatePhone = phone.reportValidity()
    //   const validateAddress = address.reportValidity()
    //   const validateDeliveryAddress = deliveryAddress.reportValidity()
    //   const validateEmail = email.reportValidity()

    //   if (
    //     !validateName ||
    //     !validatePhone ||
    //     !validateAddress ||
    //     !validateDeliveryAddress ||
    //     !validateEmail
    //   ) return false
    // }

    if (id === 'step2') {
      // validate step 1 before going to step 2
      const validateCarMaker = carMaker.reportValidity()
      const validateCarModel = carModel.reportValidity()
      const validateCarType = carType.reportValidity()
      const validateWheel = wheel.reportValidity()
      const validateSize = size.reportValidity()
      const validateMessage = message.reportValidity()
      const validateCap = cap.reportValidity()

      if (
        !validateCarMaker ||
        !validateCarModel ||
        !validateCarType ||
        !validateWheel ||
        !validateSize ||
        !validateMessage ||
        !validateCap
      ) return
    }

    for (const step of stepTabs) {
      if (step.dataset.for === id) {
        step.classList.add('active')
      } else {
        step.classList.remove('active')
      }
    }

    for (const step of steps) {
      step.classList.remove('active')
    }

    document
      .getElementById(id)
      .classList
      .add('active')

    const OFFSET = 200
    const y = form.getBoundingClientRect().top + window.pageYOffset - OFFSET
    window.scrollTo({ top: y })
  }

  for (const step of stepTabs) {
    step.addEventListener('click', function () {
      handleTabChange(this.dataset.for)
    })
  }

  function handleSubmit (e) {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = Object.fromEntries(formData)
    // const data = {
    //   car_maker: 'Toyota',
    //   car_model: 'Yaris',
    //   car_type: '65ew4t6gr4',
    //   wheel: 'rf1',
    //   size: '22インチ・RIM:9.5J・INSET:23・PCD:130',
    //   message: 'かっこよくしてください',
    //   cap: '謎',
    //   name: 'サンプル・タロウ',
    //   phone: '08000000000',
    //   address: '名古屋市',
    //   delivery_address: '名古屋市',
    //   email: 'fabien.webdev@gmail.com'
    // }

    console.log('data', data)

    formError.innerHTML = ''

    if (
      data?.car_maker &&
      data?.car_model &&
      data?.car_year &&
      data?.car_type &&
      data?.wheel &&
      data?.size &&
      data?.name &&
      data?.phone &&
      data?.address &&
      data?.delivery_address &&
      data?.email
    ) {
      const url = 'https://www.ame-wheels.jp/mailform/order_mail.php'
      form.classList.remove('sent')
      form.classList.add('loading')

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'no-cors',
        body: JSON.stringify(data)
      })
        .then(function (response) {
          console.log('response', response)
          if (response.ok || response.type === 'opaque') {
            form.innerHTML = 'ありがとうございます。\n注文を受け取りました。'
            form.classList.add('sent')
          } else {
            formError.innerHTML = '通信エラーが発生しました'
          }
          form.classList.remove('loading')
        })
        .catch(function (error) {
          console.log(error)
          formError.innerHTML = '通信エラーが発生しました'
          form.classList.remove('loading')
        })
    }
  }

  form.addEventListener('submit', handleSubmit)
}
function orderImage () {
  const form = document.getElementById('order_form')
  if (!form) return

  const modal = document.getElementById('gallery-modal')
  const mainImage = document.getElementById('zoomed-image')
  const closeButton = document.getElementById('close-modal')

  const closeModal = function () {
    modal.classList.remove('opened')
    mainImage.removeAttribute('src')
  }

  closeButton.addEventListener('click', function () {
    closeModal()
  })

  modal.addEventListener('click', function (e) {
    if (e.target.classList.contains('outer') || e.target.classList.contains('inner')) {
      closeModal()
    }
  })

  const imageRefs = document.querySelectorAll('.img-ref')
  for (const ref of imageRefs) {
    ref.addEventListener('click', function (e) {
      const src = this.src
      mainImage.setAttribute('src', src)
      modal.classList.add('opened')
    })
  }
}

domReady(() => {
  burger()

  spNav()

  scrollTop()

  landing()

  gallery()

  contact()

  maps()

  scrollHint()

  orderSteps()
  orderImage()

  setTimeout(() => {
    parallax()
    animate()
  }, 100)

  window.addEventListener('resize', parallax)
})
